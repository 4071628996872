<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <p class="card-title-desc"></p>
            <!-- skeleton  -->

            <form class="needs-validation" @submit.prevent="formSubmit">
              <div class="row">
                <div class="col-md-4 col-sm-6">
                  <div class="mb-3 position-relative">
                    <label for="groupId">กลุ่มอะไหล่</label>
                    <multiselect
                      id="groupId"
                      v-model="formPart.groupId"
                      :options="optionsGroupId"
                      class=""
                      :class="{
                        'is-invalid': submitform && $v.formPart.groupId.$error,
                      }"
                      label="nameTh"
                      track-by="nameTh"
                      :show-labels="false"
                    >
                    </multiselect>

                    <div
                      v-if="submitform && $v.formPart.groupId.$error"
                      class="invalid-tooltip"
                    >
                      <span v-if="!$v.formPart.groupId.required">{{
                        message
                      }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-sm-4">
                  <div class="mb-3 position-relative">
                    <label for="partCode"> <code>*</code> รหัสอะไหล่</label>
                    <!-- disabled="disabled"  -->
                    <input
                      id="partCode"
                      v-model="form.partCode"
                      type="text"
                      class="form-control"
                      value="Otto"
                      :class="{
                        'is-invalid': submitform && $v.form.partCode.$error,
                      }"
                    />
                    <div
                      v-if="submitform && $v.form.partCode.$error"
                      class="invalid-tooltip"
                    >
                      <span v-if="!$v.form.partCode.required">{{
                        message
                      }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-sm-4">
                  <div class="mb-3 position-relative">
                    <label for="nameTh">ชื่ออะไหล่ (ภาษาไทย)</label>
                    <div class="input-group">
                      <input
                        id="nameTh"
                        v-model="formPart.nameTh"
                        type="text"
                        class="form-control"
                        placeholder="ชื่ออะไหล่ (ภาษาไทย)"
                        :class="{
                          'is-invalid': submitform && $v.formPart.nameTh.$error,
                        }"
                      />
                      <div
                        v-if="submitform && $v.formPart.nameTh.$error"
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.formPart.nameTh.required">{{
                          message
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-sm-4">
                  <div class="mb-3 position-relative">
                    <label for="nameEn">ชื่ออะไหล่ (ภาษาอังกฤษ)</label>
                    <div class="input-group">
                      <input
                        id="nameEn"
                        v-model="formPart.nameEn"
                        type="text"
                        class="form-control"
                        placeholder="ชื่ออะไหล่ (ภาษาอังกฤษ)"
                        :class="{
                          'is-invalid': submitform && $v.formPart.nameEn.$error,
                        }"
                      />
                      <div
                        v-if="submitform && $v.formPart.nameEn.$error"
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.formPart.nameEn.required">{{
                          message
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-2 col-sm-3">
                  <div class="mb-3 position-relative">
                    <label for="year">ปี (ค.ศ.)</label>
                    <input
                      size="4"
                      id="year"
                      v-model="formPart.year"
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid': submitform && $v.formPart.year.$error,
                      }"
                    />

                    <div
                      v-if="submitform && $v.formPart.year.$error"
                      class="invalid-tooltip"
                    >
                      <span
                        v-if="!$v.formPart.year.numeric || !$v.formPart.year.maxLength"
                        >{{ message }}</span
                      >
                    </div>
                  </div>
                </div>
                <!-- <div class="col-md-4 col-sm-4">
                  <div class="mb-3 position-relative">
                    <label for="storage">ที่เก็บ</label>

                    <multiselect
                      id="storage"
                      v-model="form.storage"
                      :options="options"
                      class=""
                      :class="{
                        'is-invalid': submitform && $v.form.storage.$error,
                      }"
                    ></multiselect>
                    <div
                      v-if="submitform && $v.form.storage.$error"
                      class="invalid-tooltip"
                    >
                      <span v-if="!$v.form.storage.required">{{
                        message
                      }}</span>
                    </div>
                  </div>
                </div> -->

                <div class="col-6 col-md-2 col-sm-3">
                  <div class="mb-3 position-relative">
                    <label for="costPrice">ต้นทุน</label>
                    <div class="input-group">
                      <input
                        separator=","
                        :min="0.0"
                        id="costPrice"
                        v-model="formPart.costPrice"
                        type="text"
                        class="form-control"
                        placeholder=""
                        aria-describedby="validationTooltipUsernamePrepend"
                        :class="{
                          'is-invalid': submitform && $v.formPart.costPrice.$error,
                        }"
                      />
                      <div
                        v-if="submitform && $v.formPart.costPrice.$error"
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.formPart.costPrice.required">{{
                          message
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-md-2 col-sm-3">
                  <div class="mb-3 position-relative">
                    <label for="sellPrice">ราคาขาย/หน่วย (1)</label>
                    <div class="input-group">
                      <input
                        :min="0.0"
                        separator=","
                        id="sellPrice"
                        v-model="formPart.sellPrice"
                        type="text"
                        class="form-control"
                        placeholder=""
                        aria-describedby="validationTooltipUsernamePrepend"
                        :class="{
                          'is-invalid': submitform && $v.formPart.sellPrice.$error,
                        }"
                      />
                      <div
                        v-if="submitform && $v.formPart.sellPrice.$error"
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.formPart.sellPrice.required">{{
                          message
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-md-2 col-sm-3">
                  <div class="mb-3 position-relative">
                    <label for="sellPrice2">ราคาขาย/หน่วย (2)</label>
                    <div class="input-group">
                      <input
                        :min="0.0"
                        separator=","
                        id="sellPrice2"
                        v-model="formPart.sellPrice2"
                        type="text"
                        class="form-control"
                        placeholder=""
                        aria-describedby="validationTooltipUsernamePrepend"
                        :class="{
                          'is-invalid': submitform && $v.formPart.sellPrice2.$error,
                        }"
                      />
                      <div
                        v-if="submitform && $v.formPart.sellPrice2.$error"
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.formPart.sellPrice2.required">{{
                          message
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-md-2 col-sm-3">
                  <div class="mb-3 position-relative">
                    <label for="sellPrice3">ราคาขาย/หน่วย (3)</label>
                    <div class="input-group">
                      <input
                        :min="0.0"
                        separator=","
                        id="sellPrice3"
                        v-model="formPart.sellPrice3"
                        type="text"
                        class="form-control"
                        placeholder=""
                        aria-describedby="validationTooltipUsernamePrepend"
                        :class="{
                          'is-invalid': submitform && $v.formPart.sellPrice3.$error,
                        }"
                      />
                      <div
                        v-if="submitform && $v.formPart.sellPrice3.$error"
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.formPart.sellPrice3.required">{{
                          message
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-2 col-sm-4">
                  <div class="mb-3 position-relative">
                    <label for="unitId">หน่วยซื้อ/หน่วยขาย</label>

                    <multiselect
                      id="unitId"
                      separator=","
                      v-model="formPart.unitId"
                      :options="optionsUnits"
                      :class="{
                        'is-invalid': submitform && $v.formPart.unitId.$error,
                      }"
                      label="nameTh"
                      track-by="nameTh"
                      :show-labels="false"
                    ></multiselect>
                    <div
                      v-if="submitform && $v.formPart.unitId.$error"
                      class="invalid-tooltip"
                    >
                      <span v-if="!$v.formPart.unitId.required">{{ message }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-6 col-md-2 col-sm-3">
                  <div class="mb-3 position-relative">
                    <label for="maxDcPer">ส่วนลดสูงสุด(%)</label>
                    <div class="input-group">
                      <input
                        min="0"
                        max="100"
                        id="maxDcPer"
                        v-model="formPart.maxDcPer"
                        type="text"
                        class="form-control"
                        placeholder=""
                        :class="{
                          'is-invalid': submitform && $v.formPart.maxDcPer.$error,
                        }"
                      />
                      <div
                        v-if="submitform && $v.formPart.maxDcPer.$error"
                        class="invalid-tooltip"
                      >
                        <span
                          v-if="
                            !$v.formPart.maxDcPer.maxValue ||
                            !$v.formPart.maxDcPer.minValue
                          "
                          >{{ message }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-md-2 col-sm-3">
                  <div class="mb-3 position-relative">
                    <label for="maxDcAmount">ส่วนลดสูงสุด(บาท)</label>
                    <div class="input-group">
                      <input
                        :min="1"
                        separator=","
                        id="maxDcAmount"
                        v-model="formPart.maxDcAmount"
                        type="text"
                        class="form-control"
                        placeholder=""
                        :class="{
                          'is-invalid':
                            submitform && $v.formPart.maxDcAmount.$error,
                        }"
                      />
                      <div
                        v-if="submitform && $v.formPart.maxDcAmount.$error"
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.formPart.maxDcAmount.required">{{
                          message
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-md-2 col-sm-3">
                  <div class="mb-3 position-relative">
                    <label for="stockMax">Max</label>
                    <div class="input-group">
                      <input
                        :min="1"
                        maxlength="11"
                        minlength="1"
                        id="stockMax"
                        v-model="formPart.stockMax"
                        type="text"
                        class="form-control"
                        placeholder=""
                        :class="{
                          'is-invalid': submitform && $v.formPart.stockMax.$error,
                        }"
                      />
                      <div
                        v-if="submitform && $v.formPart.stockMax.$error"
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.formPart.stockMax.maxLength">{{
                          message
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-md-2 col-sm-3">
                  <div class="mb-3 position-relative">
                    <label for="stockMin">Min</label>
                    <div class="input-group">
                      <input
                        :min="1"
                        id="stockMin"
                        v-model="formPart.stockMin"
                        type="text"
                        class="form-control"
                        placeholder=""
                        :class="{
                          'is-invalid': submitform && $v.formPart.stockMin.$error,
                        }"
                      />
                      <div
                        v-if="submitform && $v.formPart.stockMin.$error"
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.formPart.stockMin.maxLength">{{
                          message
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div class="row">
                <div class="col-md">
                  <b-form-checkbox
                    v-model="formPart.useInBp"
                    value="1"
                    unchecked-value="0"
                    class="mb-3"
                    checked
                    plain
                    >ใช้กับศูนย์บริการ</b-form-checkbox
                  >
                  <b-form-checkbox
                    v-model="formPart.useInGs"
                    unchecked-value="0"
                    value="1"
                    class="mb-3"
                    checked
                    plain
                    >ใช้กับศูนย์ซ่อมสีและตัวถัง</b-form-checkbox
                  >
                </div>
              </div>

              <div class="col-md">
                <button class="btn btn-success float-end" type="submit">
                  บันทึก
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <!-- end card -->
    </div>

    <!-- end col -->
  </Layout>
</template>
<script>
import {
  required,
  maxValue,
  minValue,
  numeric,
  maxLength,
} from "vuelidate/lib/validators";

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";
import Swal from "sweetalert2";
import useNetw from "@useNetw";

/**
 * Form validation component
 */
export default {
  mounted() {},
  created() {
    this.getUnits();
    this.getGroups();
    // console.log(this.form.codeType);
  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
  },
  page: {
    title: "Inventory",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      loading: undefined,
      message: "โปรดระบุข้อมูล",
      title: "เพิ่มข้อมูลอะไหล่",
      items: [
        {
          text: "ข้อมูลอะไหล่",
          href: "/parts/master-part",
        },
        {
          text: "เพิ่มข้อมูลอะไหล่",
          active: true,
        },
      ],

      optionsYear: [],
      unitId: null,

      codeType: null,
      groupId: null,
      id: "",
      optionsGroupId: [],
      optionsUnits: [],
      options: [],
      use: [
        { text: "ใช้กับศูนย์ซ่อมสีและตัวถัง", value: "1" },
        { text: "ใช้กับศูนย์บริการ", value: "2" },
      ],
      opcodeType: [
        {
          name: "อะไหล่นอก",
          id: "EX",
        },
        {
          name: "กำหนดเอง",
          id: "CUSTOM",
        },
      ],
      form: {
        partCode: "",
       
      },
      formPart:{
         groupId: "",
        nameTh: "",
        nameEn: "",
        year: "",
        costPrice: "",
        sellPrice: "",
        sellPrice2: "",
        sellPrice3: "",
        unitId: "",
        maxDcPer: "",
        maxDcAmount: "",
        stockMin: "",
        stockMax: "",
        useInBp: "",
        useInGs: "",
        codeType: "",
      },

      submitform: false,
    };
  },

  validations: {
    form: {
      partCode: {
        required,
      },
    },
    formPart: {
      groupId: {},

      nameTh: {},
      nameEn: {},
      year: {
        numeric,
        maxLength: maxLength(4),
      },
      costPrice: {},
      sellPrice: {},
      sellPrice2: {},
      sellPrice3: {},
      unitId: {},
      maxDcPer: {
        maxValue: maxValue(100),
        minValue: minValue(0),
      },
      maxDcAmount: {},
      stockMin: {
        maxLength: maxLength(11),
      },
      stockMax: {
        maxLength: maxLength(11),
      },
      useInBp: {},
      useInGs: {},

      codeType: {
        required,
      },
    },
  },

  methods: {
    // eslint-disable-next-line no-unused-vars
    formSubmit() {
      this.submitform = true;
      this.$v.$touch();
      if (this.$v.form.$invalid != true) {
        this.postParts();
      }
    },
    getGroups: function () {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/master/part/groups", {})
        .then((response) => {
          this.optionsGroupId = response.data.data;
          // console.log(this.optionsGroupId);
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    getUnits: function () {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/master/part/units", {})
        .then((response) => {
          this.optionsUnits = response.data.data;
          // console.log(this.optionsUnits);
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },

    postParts: function () {
      this.overlayFlag = true;
      const bodyFormData = new FormData();

      // parse
      bodyFormData.append(
        "partCode",
        this.form.partCode != null ? this.form.partCode : ""
      );
      bodyFormData.append(
        "codeType",
        this.formPart.codeType.id != null ? this.formPart.codeType.id : ""
      );
      bodyFormData.append(
        "groupId",
        this.formPart.groupId.groupId != null ? this.formPart.groupId.groupId : ""
      );
      bodyFormData.append(
        "nameTh",
        this.formPart.nameTh != null ? this.formPart.nameTh : ""
      );
      bodyFormData.append(
        "nameEn",
        this.formPart.nameEn != null ? this.formPart.nameEn : ""
      );
      bodyFormData.append("year", this.formPart.year != null ? this.formPart.year : "");
      bodyFormData.append(
        "costPrice",
        this.formPart.costPrice != null ? this.formPart.costPrice : ""
      );
      bodyFormData.append(
        "sellPrice",
        this.formPart.sellPrice != null ? this.formPart.sellPrice : ""
      );
      bodyFormData.append(
        "sellPrice2",
        this.formPart.sellPrice2 != null ? this.formPart.sellPrice2 : ""
      );
      bodyFormData.append(
        "sellPrice3",
        this.formPart.sellPrice3 != null ? this.formPart.sellPrice3 : ""
      );
      bodyFormData.append(
        "unitId",
        this.formPart.unitId.unitId != null ? this.formPart.unitId.unitId : ""
      );
      bodyFormData.append(
        "maxDcPer",
        this.formPart.maxDcPer != null ? this.formPart.maxDcPer : ""
      );
      bodyFormData.append(
        "maxDcAmount",
        this.formPart.maxDcAmount != null ? this.formPart.maxDcAmount : ""
      );
      bodyFormData.append(
        "stockMin",
        this.formPart.stockMin != null ? this.formPart.stockMin : ""
      );
      bodyFormData.append(
        "stockMax",
        this.formPart.stockMax != null ? this.formPart.stockMax : ""
      );
      bodyFormData.append(
        "useInBp",
        this.formPart.useInBp != null ? this.formPart.useInBp : "0"
      );
      bodyFormData.append(
        "useInGs",
        this.formPart.useInGs != null ? this.formPart.useInGs : "0"
      );

      useNetw
        .post("api/central/part/store", bodyFormData)
        .then((response) => {
          // console.log(response);
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.$router.push({ name: "master-part" });
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
  },
  middleware: "authentication",
};
</script>

<style lang="scss" scoped></style>
