var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Layout',[_c('PageHeader',{attrs:{"title":_vm.title,"items":_vm.items}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('p',{staticClass:"card-title-desc"}),_c('form',{staticClass:"needs-validation",on:{"submit":function($event){$event.preventDefault();return _vm.formSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4 col-sm-6"},[_c('div',{staticClass:"mb-3 position-relative"},[_c('label',{attrs:{"for":"groupId"}},[_vm._v("กลุ่มอะไหล่")]),_c('multiselect',{class:{
                      'is-invalid': _vm.submitform && _vm.$v.formPart.groupId.$error,
                    },attrs:{"id":"groupId","options":_vm.optionsGroupId,"label":"nameTh","track-by":"nameTh","show-labels":false},model:{value:(_vm.formPart.groupId),callback:function ($$v) {_vm.$set(_vm.formPart, "groupId", $$v)},expression:"formPart.groupId"}}),(_vm.submitform && _vm.$v.formPart.groupId.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.formPart.groupId.required)?_c('span',[_vm._v(_vm._s(_vm.message))]):_vm._e()]):_vm._e()],1)]),_c('div',{staticClass:"col-sm-4"},[_c('div',{staticClass:"mb-3 position-relative"},[_c('label',{attrs:{"for":"partCode"}},[_c('code',[_vm._v("*")]),_vm._v(" รหัสอะไหล่")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.partCode),expression:"form.partCode"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.submitform && _vm.$v.form.partCode.$error,
                    },attrs:{"id":"partCode","type":"text","value":"Otto"},domProps:{"value":(_vm.form.partCode)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "partCode", $event.target.value)}}}),(_vm.submitform && _vm.$v.form.partCode.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.form.partCode.required)?_c('span',[_vm._v(_vm._s(_vm.message))]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-sm-4"},[_c('div',{staticClass:"mb-3 position-relative"},[_c('label',{attrs:{"for":"nameTh"}},[_vm._v("ชื่ออะไหล่ (ภาษาไทย)")]),_c('div',{staticClass:"input-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formPart.nameTh),expression:"formPart.nameTh"}],staticClass:"form-control",class:{
                        'is-invalid': _vm.submitform && _vm.$v.formPart.nameTh.$error,
                      },attrs:{"id":"nameTh","type":"text","placeholder":"ชื่ออะไหล่ (ภาษาไทย)"},domProps:{"value":(_vm.formPart.nameTh)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formPart, "nameTh", $event.target.value)}}}),(_vm.submitform && _vm.$v.formPart.nameTh.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.formPart.nameTh.required)?_c('span',[_vm._v(_vm._s(_vm.message))]):_vm._e()]):_vm._e()])])]),_c('div',{staticClass:"col-sm-4"},[_c('div',{staticClass:"mb-3 position-relative"},[_c('label',{attrs:{"for":"nameEn"}},[_vm._v("ชื่ออะไหล่ (ภาษาอังกฤษ)")]),_c('div',{staticClass:"input-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formPart.nameEn),expression:"formPart.nameEn"}],staticClass:"form-control",class:{
                        'is-invalid': _vm.submitform && _vm.$v.formPart.nameEn.$error,
                      },attrs:{"id":"nameEn","type":"text","placeholder":"ชื่ออะไหล่ (ภาษาอังกฤษ)"},domProps:{"value":(_vm.formPart.nameEn)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formPart, "nameEn", $event.target.value)}}}),(_vm.submitform && _vm.$v.formPart.nameEn.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.formPart.nameEn.required)?_c('span',[_vm._v(_vm._s(_vm.message))]):_vm._e()]):_vm._e()])])]),_c('div',{staticClass:"col-md-2 col-sm-3"},[_c('div',{staticClass:"mb-3 position-relative"},[_c('label',{attrs:{"for":"year"}},[_vm._v("ปี (ค.ศ.)")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formPart.year),expression:"formPart.year"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.submitform && _vm.$v.formPart.year.$error,
                    },attrs:{"size":"4","id":"year","type":"text"},domProps:{"value":(_vm.formPart.year)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formPart, "year", $event.target.value)}}}),(_vm.submitform && _vm.$v.formPart.year.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.formPart.year.numeric || !_vm.$v.formPart.year.maxLength)?_c('span',[_vm._v(_vm._s(_vm.message))]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-6 col-md-2 col-sm-3"},[_c('div',{staticClass:"mb-3 position-relative"},[_c('label',{attrs:{"for":"costPrice"}},[_vm._v("ต้นทุน")]),_c('div',{staticClass:"input-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formPart.costPrice),expression:"formPart.costPrice"}],staticClass:"form-control",class:{
                        'is-invalid': _vm.submitform && _vm.$v.formPart.costPrice.$error,
                      },attrs:{"separator":",","min":0.0,"id":"costPrice","type":"text","placeholder":"","aria-describedby":"validationTooltipUsernamePrepend"},domProps:{"value":(_vm.formPart.costPrice)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formPart, "costPrice", $event.target.value)}}}),(_vm.submitform && _vm.$v.formPart.costPrice.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.formPart.costPrice.required)?_c('span',[_vm._v(_vm._s(_vm.message))]):_vm._e()]):_vm._e()])])]),_c('div',{staticClass:"col-6 col-md-2 col-sm-3"},[_c('div',{staticClass:"mb-3 position-relative"},[_c('label',{attrs:{"for":"sellPrice"}},[_vm._v("ราคาขาย/หน่วย (1)")]),_c('div',{staticClass:"input-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formPart.sellPrice),expression:"formPart.sellPrice"}],staticClass:"form-control",class:{
                        'is-invalid': _vm.submitform && _vm.$v.formPart.sellPrice.$error,
                      },attrs:{"min":0.0,"separator":",","id":"sellPrice","type":"text","placeholder":"","aria-describedby":"validationTooltipUsernamePrepend"},domProps:{"value":(_vm.formPart.sellPrice)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formPart, "sellPrice", $event.target.value)}}}),(_vm.submitform && _vm.$v.formPart.sellPrice.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.formPart.sellPrice.required)?_c('span',[_vm._v(_vm._s(_vm.message))]):_vm._e()]):_vm._e()])])]),_c('div',{staticClass:"col-6 col-md-2 col-sm-3"},[_c('div',{staticClass:"mb-3 position-relative"},[_c('label',{attrs:{"for":"sellPrice2"}},[_vm._v("ราคาขาย/หน่วย (2)")]),_c('div',{staticClass:"input-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formPart.sellPrice2),expression:"formPart.sellPrice2"}],staticClass:"form-control",class:{
                        'is-invalid': _vm.submitform && _vm.$v.formPart.sellPrice2.$error,
                      },attrs:{"min":0.0,"separator":",","id":"sellPrice2","type":"text","placeholder":"","aria-describedby":"validationTooltipUsernamePrepend"},domProps:{"value":(_vm.formPart.sellPrice2)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formPart, "sellPrice2", $event.target.value)}}}),(_vm.submitform && _vm.$v.formPart.sellPrice2.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.formPart.sellPrice2.required)?_c('span',[_vm._v(_vm._s(_vm.message))]):_vm._e()]):_vm._e()])])]),_c('div',{staticClass:"col-6 col-md-2 col-sm-3"},[_c('div',{staticClass:"mb-3 position-relative"},[_c('label',{attrs:{"for":"sellPrice3"}},[_vm._v("ราคาขาย/หน่วย (3)")]),_c('div',{staticClass:"input-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formPart.sellPrice3),expression:"formPart.sellPrice3"}],staticClass:"form-control",class:{
                        'is-invalid': _vm.submitform && _vm.$v.formPart.sellPrice3.$error,
                      },attrs:{"min":0.0,"separator":",","id":"sellPrice3","type":"text","placeholder":"","aria-describedby":"validationTooltipUsernamePrepend"},domProps:{"value":(_vm.formPart.sellPrice3)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formPart, "sellPrice3", $event.target.value)}}}),(_vm.submitform && _vm.$v.formPart.sellPrice3.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.formPart.sellPrice3.required)?_c('span',[_vm._v(_vm._s(_vm.message))]):_vm._e()]):_vm._e()])])]),_c('div',{staticClass:"col-md-2 col-sm-4"},[_c('div',{staticClass:"mb-3 position-relative"},[_c('label',{attrs:{"for":"unitId"}},[_vm._v("หน่วยซื้อ/หน่วยขาย")]),_c('multiselect',{class:{
                      'is-invalid': _vm.submitform && _vm.$v.formPart.unitId.$error,
                    },attrs:{"id":"unitId","separator":",","options":_vm.optionsUnits,"label":"nameTh","track-by":"nameTh","show-labels":false},model:{value:(_vm.formPart.unitId),callback:function ($$v) {_vm.$set(_vm.formPart, "unitId", $$v)},expression:"formPart.unitId"}}),(_vm.submitform && _vm.$v.formPart.unitId.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.formPart.unitId.required)?_c('span',[_vm._v(_vm._s(_vm.message))]):_vm._e()]):_vm._e()],1)]),_c('div',{staticClass:"col-6 col-md-2 col-sm-3"},[_c('div',{staticClass:"mb-3 position-relative"},[_c('label',{attrs:{"for":"maxDcPer"}},[_vm._v("ส่วนลดสูงสุด(%)")]),_c('div',{staticClass:"input-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formPart.maxDcPer),expression:"formPart.maxDcPer"}],staticClass:"form-control",class:{
                        'is-invalid': _vm.submitform && _vm.$v.formPart.maxDcPer.$error,
                      },attrs:{"min":"0","max":"100","id":"maxDcPer","type":"text","placeholder":""},domProps:{"value":(_vm.formPart.maxDcPer)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formPart, "maxDcPer", $event.target.value)}}}),(_vm.submitform && _vm.$v.formPart.maxDcPer.$error)?_c('div',{staticClass:"invalid-tooltip"},[(
                          !_vm.$v.formPart.maxDcPer.maxValue ||
                          !_vm.$v.formPart.maxDcPer.minValue
                        )?_c('span',[_vm._v(_vm._s(_vm.message))]):_vm._e()]):_vm._e()])])]),_c('div',{staticClass:"col-6 col-md-2 col-sm-3"},[_c('div',{staticClass:"mb-3 position-relative"},[_c('label',{attrs:{"for":"maxDcAmount"}},[_vm._v("ส่วนลดสูงสุด(บาท)")]),_c('div',{staticClass:"input-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formPart.maxDcAmount),expression:"formPart.maxDcAmount"}],staticClass:"form-control",class:{
                        'is-invalid':
                          _vm.submitform && _vm.$v.formPart.maxDcAmount.$error,
                      },attrs:{"min":1,"separator":",","id":"maxDcAmount","type":"text","placeholder":""},domProps:{"value":(_vm.formPart.maxDcAmount)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formPart, "maxDcAmount", $event.target.value)}}}),(_vm.submitform && _vm.$v.formPart.maxDcAmount.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.formPart.maxDcAmount.required)?_c('span',[_vm._v(_vm._s(_vm.message))]):_vm._e()]):_vm._e()])])]),_c('div',{staticClass:"col-6 col-md-2 col-sm-3"},[_c('div',{staticClass:"mb-3 position-relative"},[_c('label',{attrs:{"for":"stockMax"}},[_vm._v("Max")]),_c('div',{staticClass:"input-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formPart.stockMax),expression:"formPart.stockMax"}],staticClass:"form-control",class:{
                        'is-invalid': _vm.submitform && _vm.$v.formPart.stockMax.$error,
                      },attrs:{"min":1,"maxlength":"11","minlength":"1","id":"stockMax","type":"text","placeholder":""},domProps:{"value":(_vm.formPart.stockMax)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formPart, "stockMax", $event.target.value)}}}),(_vm.submitform && _vm.$v.formPart.stockMax.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.formPart.stockMax.maxLength)?_c('span',[_vm._v(_vm._s(_vm.message))]):_vm._e()]):_vm._e()])])]),_c('div',{staticClass:"col-6 col-md-2 col-sm-3"},[_c('div',{staticClass:"mb-3 position-relative"},[_c('label',{attrs:{"for":"stockMin"}},[_vm._v("Min")]),_c('div',{staticClass:"input-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formPart.stockMin),expression:"formPart.stockMin"}],staticClass:"form-control",class:{
                        'is-invalid': _vm.submitform && _vm.$v.formPart.stockMin.$error,
                      },attrs:{"min":1,"id":"stockMin","type":"text","placeholder":""},domProps:{"value":(_vm.formPart.stockMin)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formPart, "stockMin", $event.target.value)}}}),(_vm.submitform && _vm.$v.formPart.stockMin.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.formPart.stockMin.maxLength)?_c('span',[_vm._v(_vm._s(_vm.message))]):_vm._e()]):_vm._e()])])])]),_c('br'),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md"},[_c('b-form-checkbox',{staticClass:"mb-3",attrs:{"value":"1","unchecked-value":"0","checked":"","plain":""},model:{value:(_vm.formPart.useInBp),callback:function ($$v) {_vm.$set(_vm.formPart, "useInBp", $$v)},expression:"formPart.useInBp"}},[_vm._v("ใช้กับศูนย์บริการ")]),_c('b-form-checkbox',{staticClass:"mb-3",attrs:{"unchecked-value":"0","value":"1","checked":"","plain":""},model:{value:(_vm.formPart.useInGs),callback:function ($$v) {_vm.$set(_vm.formPart, "useInGs", $$v)},expression:"formPart.useInGs"}},[_vm._v("ใช้กับศูนย์ซ่อมสีและตัวถัง")])],1)]),_c('div',{staticClass:"col-md"},[_c('button',{staticClass:"btn btn-success float-end",attrs:{"type":"submit"}},[_vm._v(" บันทึก ")])])])])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }